






































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import MaterialSelect from '@/components/material-select/index.vue'
import Specification from './specification.vue'
@Component({
  components: {
    MaterialSelect,
    Specification
  }
})
export default class AddPrice extends Vue {
  @Prop() value: any
  @Prop() specData!: any
  mounted () {}
}
